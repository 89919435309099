import React, { useState, useEffect } from "react";
import "./css/Products.css";
import "./scss/textanimation.scss";
import background from "./images/5J3A3876.JPG";
import Product from "./Product";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { BlinkingCursorTextBuilder } from "react-animated-text-builders";
import { FloatingLettersTextBuilder } from "react-animated-text-builders";
import { Wave } from "react-animated-text";

import img2 from "./images/5J3A3907.jpg";

import img9 from "./images/5J3A4469.jpg";
import img10 from "./images/terscevrilmis.jpg";
import img11 from "./images/5J3A4461.jpg";
import welherts from "./images/normals/Welherts.jpg";
import blader from "./images/blade.png";
import bohler from "./images/normals/bohler-miller.jpeg";
import wabert from "./images/normals/wabert.png";

const Products = () => {
    const products = [
        {
            backgroundImage: img2,
            frontImage: bohler,
            upperSpan:
                "Dişleri Sertleştirilmiş, Bileyli ve Çaprazlı Ahşap Kesen Şerit Testereler",
            key: "1",
            smallHeader: "smallHeader",
        },
        {
            backgroundImage: img9,
            frontImage: bohler,
            upperSpan: "Ahşap Kesen Çelik Şerit Testereler ",
            key: "2",
        },
        {
            backgroundImage: img10,
            frontImage: null,
            upperSpan: "Et ve Balık Testereleri",
            key: "3",
        },
        {
            backgroundImage: blader,
            frontImage: welherts,
            upperSpan: "M42 Bi-Metal Şerit Testere",
            key: "4",
            class: "rotate",
        },
        {
            backgroundImage: blader,
            frontImage: bohler,
            upperSpan: "TCT Elmas Uçlu Daire Testereler",
            key: "5",
        },
    ];

    const [cssVisible, SetCssVisible] = useState("animation-visible");

    const change = () => {
        SetCssVisible("animation-unvisible");
        setTimeout(() => {
            SetCssVisible("animation-visible");
        }, 500);
    };

    useEffect(() => {
        change();
    }, []);

    return (
        <>
            <div className="carousel-container">
                <Carousel
                    axis="horizontal"
                    autoPlay={true}
                    dynamicHeight={true}
                    infiniteLoop={true}
                    stopOnHover={false}
                    swipeable={true}
                    transitionTime={1000}
                    renderThumbnails={false}
                    renderThumbs={() => {}}
                    onChange={change}
                    onClick={() => {}}
                    interval={4750}
                >
                    <div key={"1"}>
                        <div
                            className={
                                "animated-title animated-title-with-3-row " +
                                cssVisible
                            }
                        >
                            <div className={"text-top smallHeader"}>
                                <div>
                                    <span className={"text-top-small2"}>
                                        Dişleri Sertleştirilmiş, Bileyli ve
                                        Çaprazlı Ahşap Kesen Şerit Testereler
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                {/* <img className="text-bottom-img" src={bohler} alt="" /> */}
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={img2}
                            alt=""
                        />
                    </div>
                    <div key={"2"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    <span className="text-top-small">
                                        Ahşap Kesen Çelik Şerit Testereler
                                    </span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                <img
                                    className="text-bottom-img"
                                    src={bohler}
                                    alt=""
                                />
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={img9}
                            alt=""
                        />
                    </div>
                    <div key={"3"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    <span>Et ve Balık Testereleri</span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                {/* <img
                  className="text-bottom-img"
                  src={}
                  key={el.key}
                  alt=""
                /> */}
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={img10}
                            alt=""
                        />
                    </div>
                    <div key={"4"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    <span>M42 Bi-Metal Şerit Testere</span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                <img
                                    className="text-bottom-img"
                                    src={welherts}
                                    alt=""
                                />
                            </div>
                        </div>

                        <img
                            className={"carousel-background rotate"}
                            src={blader}
                            alt=""
                        />
                    </div>
                    <div key={"5"}>
                        <div className={"animated-title " + cssVisible}>
                            <div className={"text-top"}>
                                <div>
                                    <span>TCT Elmas Uçlu Daire Testereler</span>
                                </div>
                            </div>
                            <div className="border-div"></div>
                            <div className="text-bottom">
                                <img
                                    className="text-bottom-img"
                                    src={wabert}
                                    alt=""
                                />
                            </div>
                        </div>

                        <img
                            className={"carousel-background"}
                            src={img11}
                            alt=""
                        />
                    </div>
                </Carousel>
            </div>
        </>
    );
};

export default Products;
