import React, { useState, useEffect } from "react";
import "./css/ProductsPage.css";
import HomeHeader from "./HomeHeader";
import Products from "./Products";
import Footer from "./Footer";
import img1 from "./images/5J3A3873white.jpg";
import img2 from "./images/normals/5J3A3907.jpg";
import { Link } from "react-router-dom";
import img3 from "./images/normals/5J3A4340.jpg";
import img4 from "./images/normals/5J3A4482.jpg";
import img5 from "./images/normals/5J3A4277.jpg";
import img6 from "./images/normals/5J3A4504.jpg";
import img7 from "./images/normals/5J3A4269.jpg";

function ProductsPage() {
    return (
        <div
            className="products-page"
            // style={{ backgroundImage: "url(" + img1 + ")" }}
        >
            <div className="products-page-container">
                <h1> Ürünler</h1>
                <div className="header-products">
                    <div className="header-products-product-page">
                        <Link to="/urunler/ahsap-kesen-celik-serit-testereler">
                            <img src={img2} alt="" />
                            <div className="header-products-texts-page">
                                <h2>Ahşap Kesen</h2>
                                <h2>Çelik Şerit Testereler</h2>
                            </div>
                        </Link>
                    </div>

                    <div className="header-products-product-page">
                        <Link to="/urunler/et-ve-balik">
                            <img src={img6} alt="" />
                            <div className="header-products-texts-page">
                                <h2>Et ve Balık Testereleri</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/m42-bi-metal-serit-testere">
                            <img src={img3} alt="" />
                            <div className="header-products-texts-page">
                                <h2>M42 Bi-Metal Şerit Testere</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/disleri-sertlestirilmis-testereler">
                            <img src={img5} alt="" />
                            <div className="header-products-texts-page">
                                <h2>
                                    Dişleri Sertleştirilmiş, Bileyli ve Çaprazlı
                                    Ahşap Kesen Şerit Testereler
                                </h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/tct-elmas-uclu-daire-testereler">
                            <img src={img4} alt="" />
                            <div className="header-products-texts-page">
                                <h2>TCT Elmas Uçlu Daire Testereler</h2>
                            </div>
                        </Link>
                    </div>
                    <div className="header-products-product-page">
                        <Link to="/urunler/ahsap-testere-bileme-taslari">
                            <img src={img6} alt="" />
                            <div className="header-products-texts-page">
                                <h2>Ahşap Testere Bileme Taşları</h2>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductsPage;
