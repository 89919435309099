import React from "react";
import "./App.css";
import Home from "./components/Home";
import HomeHeader from "./components/HomeHeader";
import Communication from "./components/Communication";
import Footer from "./components/Footer";
import About from "./components/About";
import ProductsPage from "./components/ProductsPage.js";
import Services from "./components/Services.js";
import whatsapp from "./components/images/whatsapp.png";
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
} from "react-router-dom";
import NotFound from "./components/NotFound";
import OneProduct from "./components/OneProduct.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import img3 from "./components/images/5J3A4340.jpg";
import img4 from "./components/images/5J3A4482.jpg";
import img5 from "./components/images/5J3A4277.jpg";
import img6 from "./components/images/5J3A4504.jpg";
import img7 from "./components/images/5J3A4269.jpg";
import img9 from "./components/images/5J3A4469.jpg";
import img10 from "./components/images/5J3A4488.JPG";
import img11 from "./components/images/5J3A4460.JPG";
import img12 from "./components/images/5J3A4458.JPG";

import { ColorTable } from "react-color-table";
const App = () => {
  return (
    <Router>
      <div className="App">
        <HomeHeader isFixedBarAlwaysOpen={true} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/iletisim" element={<Communication />} />
          <Route exact path="/hakkimizda" element={<About />} />
          <Route exact path="/urunler" element={<ProductsPage />} />
          <Route exact path="/hizmetler" element={<Services />} />
          <Route
            exact
            path="/urunler/et-ve-balik"
            element={
              <OneProduct
                backgroundImage={img9}
                title="Et ve Balık Testereleri"
                content={[
                  "Kemikli, kemiksiz, donuk veya yumuşak et ve balık kesiminde kullanılan uçları sertleştirilmiş, yüksek performanslı testerelerdir. ",
                ]}
                image={img4}
                table="et-ve-balik"
              />
            }
          />
          <Route
            exact
            path="/urunler/ahsap-kesen-celik-serit-testereler"
            element={
              <OneProduct
                backgroundImage={img10}
                title="Ahşap Kesen Çelik Şerit Testereler"
                content={[
                  "Testere makinelerinde kullanılır.",
                  "Ahşap kesen çelik şerit testerelerdir.",
                  ,
                ]}
                image={img4}
                table="ahsap-kesen"
              />
            }
          />

          <Route
            exact
            path="/urunler/m42-bi-metal-serit-testere"
            element={
              <OneProduct
                backgroundImage={img3}
                title="M42 Bi-Metal Şerit Testere"
                content={[
                  "Metal kesen şerit testere makinelerinde kullanılan çok amaçlı şerit testerelerdir.",
                  ,
                ]}
                image={img4}
              />
            }
          />

          <Route
            exact
            path="/urunler/tct-elmas-uclu-daire-testereler"
            element={
              <OneProduct
                backgroundImage={img3}
                title="TCT Elmas Uçlu Daire Testereler"
                content={[
                  "",
                  "Ahşap, MDF, suntalam, laminant, alüminyum ve plastik kesiminde kullanılan dairesel testerelerdir.",
                  ,
                ]}
                image={img4}
              />
            }
          />
          <Route
            exact
            path="/urunler/ahsap-testere-bileme-taslari"
            element={
              <OneProduct
                backgroundImage={img3}
                title="Ahşap Testere Bileme Taşları"
                content={["", "", ,]}
                image={img4}
              />
            }
          />
          <Route
            exact
            path="/urunler/disleri-sertlestirilmis-testereler"
            element={
              <OneProduct
                backgroundImage={img11}
                title="Dişleri Sertleştirilmiş, Bileyli ve Çaprazlı Ahşap Kesen Şerit
                  Testereler"
                content={["", "", ,]}
                image={img12}
              />
            }
          />
        </Routes>
        <Footer />
        <div className="whatsapp">
          <a href="https://wa.me/message/OLUBGZ3MMWBTI1" target="new_blank">
            <img src={whatsapp} alt="" />
          </a>
        </div>
      </div>
    </Router>
  );
};

export default App;
