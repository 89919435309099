import React, { useState, useEffect } from "react";
import "./css/Services.css";
import HomeHeader from "./HomeHeader";
import Products from "./Products";
import Footer from "./Footer";
import img1 from "./images/5J3A3873white.jpg";
import img2 from "./images/5J3A3907.jpg";
import { Link } from "react-router-dom";
import img3 from "./images/normals/5J3A4340.jpg";
import vid1 from "./videos/vid.mp4";
import img4 from "./images/normals/5J3A4358.JPG";
import img5 from "./images/normals/5J3A4269.jpg";
function Services() {
    useEffect(() => {
        document.getElementById("video").volume = 0;
    }, []);

    return (
        <div className="services">
            <div className="services-container">
                <h1> Hizmetler</h1>
                <div className="services-service">
                    <img src={img3} alt="" />
                    <div className="services-text">
                        <h1>Kaynak</h1>
                        <p>
                            Ahşap, metal ve et kemik kesiminde kullanılan şerit
                            testerelerinin kaynakları uzman ekibimiz tarafından
                            hassas şekilde yapılmaktadır.
                        </p>
                    </div>
                </div>
                <div className="services-service">
                    <video
                        id="video"
                        src={vid1}
                        autoplay="true"
                        controls="true"
                        volume="0"
                    />
                </div>
                <div className="services-service">
                    <img src={img4} alt="" />
                    <img src={img5} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Services;
