import React from "react";
import "./css/Footer.css";

import boehlerstrip_logo from "./images/logo_boehlerstrip_2018.png";
import martin_miller_logo from "./images/logo_martin_miller_white_2018.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationArrow,
    faMailBulk,
    faMapMarked,
    faMapMarker,
    faMapMarkerAlt,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
    faInstagram,
    faMailchimp,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-companies">
                    <div className="footer-companies-container">
                        <img src={boehlerstrip_logo} alt="Boehlerstrip" />
                        {/* <img src={martin_miller_logo} alt="Martin Miller" /> */}
                    </div>
                </div>
                <div className="footer-address">
                    <div className="footer-address-container">
                        ÖZŞAHİNLER Makine Hırd Teknik Malzeme Tic ve San Ltd Şti{" "}
                        <br />
                        <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            color="black"
                            size="1x"
                        />
                        <span style={{ paddingLeft: "10px" }}></span>
                        OSB Metal İş San Sit 4/A. Blok No:44
                        İkitelli-Başakşehir/ Istanbul
                        <hr />
                        <div className="footer-phone">
                            <FontAwesomeIcon
                                icon={faPhone}
                                color="black"
                                size="1x"
                            />
                            <span style={{ paddingLeft: "5px" }}></span>
                            <a href="tel:02125498674">(0212) 549 86 74 </a>
                            <span style={{ paddingLeft: "20px" }}></span>
                            <a href="mailto:info@ozsahinler.com.tr">
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    color=""
                                    size="1x"
                                />
                                <span style={{ paddingLeft: "5px" }}></span>
                                info@ozsahinler.com.tr
                            </a>
                        </div>
                        <div className="footer-social">
                            <FontAwesomeIcon
                                icon={faInstagram}
                                color="black"
                                size="2x"
                            />
                            <span style={{ paddingLeft: "20px" }}></span>
                            <a
                                href="https://wa.me/message/OLUBGZ3MMWBTI1"
                                target="new_blank"
                            >
                                <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    color="black"
                                    size="2x"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
