import React, { useState, useEffect } from "react";
import "./css/Home.css";
import HomeHeader from "./HomeHeader";
import Products from "./Products";
import Footer from "./Footer";
function Home() {
  return (
    <div className="home" id="home">
      <Products />
    </div>
  );
}

export default Home;
