import React, { useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faHamburger,
    faAngleDown,
    faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import "./css/HomeHeader.css";
import Logo from "./images/ozsahinler_logo.png";
import img2 from "./images/5J3A3907.jpg";
import img3 from "./images/normals/5J3A4340.jpg";
import img4 from "./images/normals/5J3A4482.jpg";
import img5 from "./images/normals/5J3A4277.jpg";
import img6 from "./images/normals/5J3A4504.jpg";
import img7 from "./images/normals/5J3A4269.jpg";
import Typography from "@material-ui/core/Typography";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import Button from "@material-ui/core/Button";
import {
    usePopupState,
    bindHover,
    bindPopover,
} from "material-ui-popup-state/hooks";

const HomeHeader = ({ isFixedBarAlwaysOpen }) => {
    const popupState = usePopupState({
        variant: "popover",
        popupId: "demoPopover",
    });

    // const [fixNavbar, setFixNavbar] = useState(
    //   isFixedBarAlwaysOpen ? "fixed-navbar" : "header-nav-block-none"
    // );
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
    const handleHamburgerClick = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
        if (isHamburgerMenuOpen) {
        }
    };
    return (
        <>
            {/* <header className="navbar" id="nav" style={{backgroundImage: "url(" + background+ ")"}}> */}
            <header className="navbar " id="nav">
                <nav
                    className={
                        "header-nav fixed-navbar" +
                        (isHamburgerMenuOpen ? " header-nav-backgorunded" : "")
                    }
                >
                    <div className="logo-bar">
                        <Link to="/">
                            {" "}
                            <img src={Logo} />{" "}
                        </Link>
                    </div>
                    <li
                        className={
                            (isHamburgerMenuOpen
                                ? "header-nav-li-open-menu"
                                : "") + " header-first-el"
                        }
                    >
                        <Link onClick={handleHamburgerClick} to="/">
                            Anasayfa
                        </Link>
                    </li>
                    <li
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link onClick={handleHamburgerClick} to="/hakkimizda">
                            Hakkımızda
                        </Link>
                    </li>
                    <li
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link
                            onClick={handleHamburgerClick}
                            to="/urunler"
                            {...bindHover(popupState)}
                        >
                            Ürünler <FontAwesomeIcon icon={faAngleDown} />
                        </Link>
                        <HoverPopover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <Typography>
                                <div className="header-products">
                                    <div className="header-products-product">
                                        <Link to="/urunler/ahsap-kesen-celik-serit-testereler">
                                            <img src={img3} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    Ahşap Kesen Çelik Şerit
                                                    Testereler
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link to="/urunler/et-ve-balik">
                                            <img src={img2} alt="" />
                                            <div className="header-products-texts">
                                                <h3>Et ve Balık Testereleri</h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link to="/urunler/m42-bi-metal-serit-testere">
                                            <img src={img4} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    M42 Bi-Metal Şerit Testere
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link to="/urunler/disleri-sertlestirilmis-testereler">
                                            <img src={img7} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    Dişleri Sertleştirilmiş,
                                                    Bileyli ve Çaprazlı Ahşap
                                                    Kesen Şerit Testereler
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link to="/urunler/tct-elmas-uclu-daire-testereler">
                                            <img src={img5} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    TCT Elmas Uçlu Daire
                                                    Testereler
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="header-products-product">
                                        <Link to="/urunler/ahsap-testere-bileme-taslari">
                                            <img src={img6} alt="" />
                                            <div className="header-products-texts">
                                                <h3>
                                                    Ahşap Testere Bileme Taşları
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Typography>
                        </HoverPopover>
                    </li>
                    <li
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link onClick={handleHamburgerClick} to="/hizmetler">
                            Hizmetler
                        </Link>
                    </li>
                    <li
                        className={
                            isHamburgerMenuOpen ? "header-nav-li-open-menu" : ""
                        }
                    >
                        <Link onClick={handleHamburgerClick} to="/iletisim">
                            İletişim
                        </Link>
                    </li>
                    <div className="header-nav-hamburger">
                        <FontAwesomeIcon
                            onClick={handleHamburgerClick}
                            color="white"
                            icon={faBars}
                            size="2x"
                        />
                    </div>
                </nav>
            </header>
        </>
    );
};

export default HomeHeader;
