import React, { useState, useEffect } from "react";
import "./css/About.css";
import HomeHeader from "./HomeHeader";
import Products from "./Products";
import Footer from "./Footer";
import img1 from "./images/5J3A3873.jpg";

function About() {
  return (
    <div className="about" style={{ backgroundImage: "url(" + img1 + ")" }}>
      <div className="about-container">
        <div className="about-texts">
          <h1>Hakkımızda</h1>
          <p>
            Şirketimiz 1990 yılında ahşap kesiminde kullanılan şerit testere
            satışı yapmak üzere Karaköy de kurulmuştur.
          </p>
          <p className="about-texts-bold">
            1994 yılında artan ihtiyaca cevap verebilmek için şerit testere
            üretimine başlayan şirketimiz , 2002 yılında MARTİN MİLLER
            markasının Türkiye distribütörlüğünü almıştır.
          </p>
          <p>
            Ürün çeşitliliğimizi artırarak ahşap kesen şerit testerelere ilave
            olarak Et ve Balık kesen şerit testereleri , Bi-metal şerit
            testereleri , dişleri sertleştirilmiş ahşap kesen şerit testereleri
            , TCT elmas daire testereler , ahşap için planya bıçakları ithalat
            ve satış faaliyetlerimize devam etmekteyiz.
          </p>
          <h2>Hedeflerimiz</h2>
          <p>
            Kaliteli hizmet anlayışını daima ön planda tutup , müşteri
            taleplerine doğru ve hızlı çözümler üreterek , kuruluşundan bugüne
            kadar kazanmış olduğu güvenilir imajından asla taviz vermeden
            faaliyetlerini sürdürmektir.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
